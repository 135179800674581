





























































































































































import { Component, Vue } from "vue-property-decorator";

@Component({
  components: {},
})
export default class Home extends Vue {
  private async _moveToRegistrationApplicationView(): Promise<void> {
    this.$router.push("/RegistrationApplication");
  }

  private _openJpaidDatabase(): void {
    window.open("https://database.jpaid.jp", "_blank");
  }

  private _openManualPage(): void {
    window.open("./databaseSystemManual.pdf", "_blank");
  }
}
