

















import { Component, Mixins } from 'vue-property-decorator';

@Component({
  components: {
  },
})
export default class ConfirmTerm extends Mixins() {
}
