import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import Home from '../views/Home.vue';
import RegistrationApplication from '../views/RegistrationApplication.vue';
import ConfirmTerm from "../components/ConfirmTerm.vue";
import PriceChart from "../views/PriceChart.vue";
import NotFound from '../views/NotFound.vue';

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: { title: 'Home｜日本病理学会デジタル画像データベース' }
  },
  {
    path: '/RegistrationApplication',
    name: 'システム登録申請',
    component: RegistrationApplication,
    meta: { title: 'システム登録申請｜日本病理学会デジタル画像データベース' }
  },
  {
    path: '/term',
    name: '利用規約',
    component: ConfirmTerm,
    meta: { title: 'システム利用規約｜日本病理学会デジタル画像データベース' }
  },
  {
    path: '/Price',
    name: 'システム利用料金',
    component: PriceChart,
    meta: { title: 'システム利用料金｜日本病理学会デジタル画像データベース' }
  },
  {
    path: '/*',
    name: 'notfound',
    component: NotFound,
    meta: { title: 'NotFound｜日本病理学会デジタル画像データベース' }
  },
]
const DEFAULT_TITLE = 'Default Title'
const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})
router.afterEach((to, from) => {
  document.title = to.meta.title || DEFAULT_TITLE
})

export default router
