





















import { Component, Mixins } from "vue-property-decorator";
import ConfirmTerm from "../components/ConfirmTerm.vue";
import CheckInputedRegistrationParam from "../components/CheckInputedRegistrationParam.vue";

@Component({
  components: {
    ConfirmTerm,
    CheckInputedRegistrationParam,
  },
})
export default class NotFound extends Mixins() {
  private async _moveToTop(): Promise<void> {
    this.$router.push("/");
  }
}
