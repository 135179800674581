import Vue from 'vue';
import Vuex from 'vuex';
import { State } from "./State";
import { SessionStorageAccessor } from './SessionStorageAccessor';

Vue.use(Vuex);

export default new Vuex.Store({
  state: new State(),
  getters: {
    ConfirmTermFlag: state => state.ConfirmTermFlag,
    RegistrationParam: State => State.Registrationparam,
  },
  mutations: {
    SetConfirmTermFlag(state, ConfirmTermFlag) {
      Vue.set(state, "ConfirmTermFlag", ConfirmTermFlag);
      SessionStorageAccessor.ConfirmTermFlag = ConfirmTermFlag;
    },
    SetRegistrationParam(state, RegistrationParam) {
      Vue.set(state, "RegistrationParam", RegistrationParam);
      SessionStorageAccessor.registrationParam = RegistrationParam;
    },
  },
  actions: {
    SetConfirmTermFlag(context, ConfirmTermFlag) {
      context.commit("SetConfirmTermFlag", ConfirmTermFlag);
    },
    SetRegistrationParam(context, RegistrationParam) {
      context.commit("SetRegistrationParam", RegistrationParam);
    },
  },
  modules: {
  },
});