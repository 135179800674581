












































































import { Component, Mixins } from 'vue-property-decorator';
import { SessionStorageAccessor } from "../store/SessionStorageAccessor";
import { RegistrationParam } from "../entities/RegistrationParam";

@Component({
  components: {
  },
})
export default class CheckInputedRegistrationParam extends Mixins() {
    data(): any {
        return {
            param: new RegistrationParam("", "", "", "", "", "", "", "", "", "", "", "", true, "", "", "", "", false, 0, "", "", ""),
        };
    }

    created() {
        this._setParam();
    }
    
    param: RegistrationParam;

    get InputedRegistrationParam(): RegistrationParam {
        return SessionStorageAccessor.registrationParam ? SessionStorageAccessor.registrationParam : null;
    }

    private async _setParam(): Promise<void> {
        this.param = this.InputedRegistrationParam;
    }
}
