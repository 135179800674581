































































































































































































































































































































































































































































































































































import { Component, Mixins } from "vue-property-decorator";
import store from "../store";
import axios, { AxiosResponse } from "axios";
import { SessionStorageAccessor } from "../store/SessionStorageAccessor";
import ConfirmTerm from "../components/ConfirmTerm.vue";
import CheckInputedRegistrationParam from "../components/CheckInputedRegistrationParam.vue";
import { RegistrationParam, SendMailResultParam } from "../entities";

@Component({
  components: {
    ConfirmTerm,
    CheckInputedRegistrationParam,
  },
})
export default class RegistrationApplication extends Mixins() {
  data(): any {
    return {
      registrationParam: new RegistrationParam(
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        true,
        "",
        "",
        "",
        "",
        false,
        0,
        "",
        "",
        ""
      ),
      confirmTermDialogFlag: false,
      confirmTermFlag: false,
      checkRegistrationDialogFlag: false,
      loadingFlag: false,
    };
  }

  created() {
    this.confirmTermDialogFlag = true;
  }

  registrationParam: RegistrationParam;
  confirmTermDialogFlag: boolean;
  confirmTermFlag: boolean;
  checkRegistrationDialogFlag: boolean;
  loadingFlag: boolean;

  get InputedRegistrationParam(): RegistrationParam {
    return SessionStorageAccessor.registrationParam
      ? SessionStorageAccessor.registrationParam
      : null;
  }

  get registerButtonEnable(): boolean {
    if (this.registrationParam.userNameSei == "") return false;
    if (this.registrationParam.userNameMei == "") return false;
    if (this.registrationParam.userNameSeiKn == "") return false;
    if (this.registrationParam.userNameMeiKn == "") return false;
    if (this.registrationParam.userNameSeiEn == "") return false;
    if (this.registrationParam.userNameMeiEn == "") return false;
    if (this.registrationParam.userEmail == "") return false;
    if (this.registrationParam.institutionName == "") return false;
    if (this.registrationParam.institutionPostCode == "") return false;
    if (this.registrationParam.institutionAddress == "") return false;
    if (this.registrationParam.subjectName == "") return false;
    if (this.registrationParam.jspMemberFlag) {
      if (this.registrationParam.jspMembershipId == "") return false;
    } else {
      if (
        this.registrationParam.jspCouncilorName == "" ||
        this.registrationParam.jspCouncilorMembershipId == ""
      )
        return false;
    }
    if (this.registrationParam.downloadPrivilege) {
      if (this.registrationParam.plannedNumberOfImages < 1) return false;
      if (this.registrationParam.academicResearchObjective == "") return false;
      if (this.registrationParam.academicResearchPlan == "") return false;
      if (this.registrationParam.researchFunds == "") return false;
    } else {
      if (this.registrationParam.academicResearchObjective == "") return false;
    }

    return true;
  }

  private async _confirmedTerm(): Promise<void> {
    if (this.confirmTermFlag) this.confirmTermDialogFlag = false;
  }

  private async _moveToTop(): Promise<void> {
    this.$router.push("/");
  }

  private async _nmValidate(): Promise<void> {
    this.registrationParam.userNameSei = this.registrationParam.userNameSei.replace(
      /[^\S]/g,
      ""
    );
    this.registrationParam.userNameMei = this.registrationParam.userNameMei.replace(
      /[^\S]/g,
      ""
    );
  }

  private async _knValidate(): Promise<void> {
    this.registrationParam.userNameSeiKn = this.registrationParam.userNameSeiKn.replace(
      /[^ァ-ンヴー]/g,
      ""
    );
    this.registrationParam.userNameMeiKn = this.registrationParam.userNameMeiKn.replace(
      /[^ァ-ンヴー]/g,
      ""
    );
  }

  private async _enValidate(): Promise<void> {
    this.registrationParam.userNameSeiEn = this.registrationParam.userNameSeiEn.replace(
      /[^a-zA-Z]/g,
      ""
    );
    this.registrationParam.userNameMeiEn = this.registrationParam.userNameMeiEn.replace(
      /[^a-zA-Z]/g,
      ""
    );
  }

  private async _emailValidate(): Promise<void> {
    this.registrationParam.userEmail = this.registrationParam.userEmail.replace(
      /\s+/g,
      ""
    );
  }

  private async _postCodeValidate(): Promise<void> {
    this.registrationParam.institutionPostCode = this.registrationParam.institutionPostCode.replace(
      /[^0-9-]/g,
      ""
    );
  }

  private async _enableCheckRegistrationDialogFlag(): Promise<void> {
    if (this.registerButtonEnable) {
      store.commit("SetRegistrationParam", this.registrationParam);
      this.checkRegistrationDialogFlag = true;
    }
  }

  private async _registerForApply(): Promise<void> {
    this.checkRegistrationDialogFlag = false;
    this.loadingFlag = true;
    try {
      const postResult = await this.sendMailPost(this.registrationParam);
      if (postResult.result) {
        this.$message.success(
          "システム登録申請完了しました、完了通知メールをご確認ください。"
        );
        this._moveToTop();
      } else throw new Error(postResult.resultCode.toString());
    } catch (error) {
      this.$message.error("システム登録申請に失敗しました。");
    } finally {
      this.loadingFlag = false;
    }
  }

  private async sendMailPost(
    param: RegistrationParam
  ): Promise<SendMailResultParam> {
    return await (await this.httpPost(param)).data;
  }

  private async httpPost<T = any>(data?: any): Promise<AxiosResponse<T>> {
    return axios.post("./functions/mail.php", data, {
      headers: {
        "Content-Type": "application/json",
      },
    });
  }
}
