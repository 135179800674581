











































import { Component, Mixins } from "vue-property-decorator";
import { PriceParam } from "../entities";

export default class PriceChart extends Mixins() {}
