export class RegistrationParam {
    userId: string;
    userName: string;
    userNameSei: string;
    userNameMei: string;
    userNameSeiKn: string;
    userNameMeiKn: string;
    userNameSeiEn: string;
    userNameMeiEn: string;
    userEmail: string;
    institutionName: string;
    institutionPostCode: string;
    institutionAddress: string;
    jspMemberFlag: boolean;
    jspMembershipId: string;
    jspCouncilorName: string;
    jspCouncilorMembershipId: string;
    subjectName: string;
    downloadPrivilege: boolean;
    plannedNumberOfImages: number;
    academicResearchObjective: string;
    academicResearchPlan: string;
    researchFunds: string;

    constructor (userId: string,userName: string, userNameSei: string, userNameMei: string, userNameSeiKn: string, userNameMeiKn: string,
        userNameSeiEn: string,  userNameMeiEn: string, userEmail: string, institutionName: string, institutionPostCode: string, institutionAddress: string, 
        jspMemberFlag: boolean, jspMembershipId: string, jspCouncilorName: string, jspCouncilorMembershipId: string, 
        subjectName: string, downloadPrivilege: boolean, plannedNumberOfImages: number, academicResearchObjective: string, academicResearchPlan: string, researchFunds: string) {
        this.userId = userId;
        this.userName = userName;
        this.userNameSei = userNameSei;
        this.userNameMei = userNameMei;
        this.userNameSeiKn = userNameSeiKn;
        this.userNameMeiKn = userNameMeiKn;
        this.userNameSeiEn = userNameSeiEn;
        this.userNameMeiEn = userNameMeiEn;
        this.userEmail = userEmail;
        this.institutionName = institutionName;
        this.institutionPostCode = institutionPostCode;
        this.institutionAddress = institutionAddress;
        this.jspMemberFlag = jspMemberFlag;
        this.jspMembershipId = jspMembershipId;
        this.jspCouncilorName = jspCouncilorName;
        this.jspCouncilorMembershipId = jspCouncilorMembershipId;
        this.subjectName = subjectName;
        this.downloadPrivilege = downloadPrivilege;
        this.plannedNumberOfImages = plannedNumberOfImages;
        this.academicResearchObjective = academicResearchObjective;
        this.academicResearchPlan = academicResearchPlan;
        this.researchFunds = researchFunds;
    }
}