import { RegistrationParam } from "../entities/RegistrationParam";

export class SessionStorageAccessor {
  private static _confirmTermFlagKey = "ConfirmTermFlagKey";
  private static _registrationParamKey = "RegistrationParamKey";

  static get ConfirmTermFlag(): boolean {
    const text = sessionStorage.getItem(this._confirmTermFlagKey);
    if (!text) { return null; }
    const obj = JSON.parse(text);
    return obj.ConfirmTermFlag;
  }

  static set ConfirmTermFlag(value: boolean) {
    if (!value) {
      sessionStorage.removeItem(this._confirmTermFlagKey);
      return;
    }
    const text = JSON.stringify({
      ConfirmTermFlag: value
    });
    sessionStorage.setItem(this._confirmTermFlagKey, text);
  }

  static get registrationParam(): RegistrationParam {
    const text = sessionStorage.getItem(this._registrationParamKey);
    if (!text) { return null; }
    const obj = JSON.parse(text);
    return new RegistrationParam(
      obj.userId,
      obj.userName,
      obj.userNameSei,
      obj.userNameMei,
      obj.userNameSeiKn,
      obj.userNameMeiKn,
      obj.userNameSeiEn,
      obj.userNameMeiEn,
      obj.userEmail,
      obj.institutionName,
      obj.institutionPostCode,
      obj.institutionAddress,
      obj.jspMemberFlag,
      obj.jspMembershipId,
      obj.jspCouncilorName,
      obj.jspCouncilorMembershipId,
      obj.subjectName,
      obj.downloadPrivilege,
      obj.plannedNumberOfImages,
      obj.academicResearchObjective,
      obj.academicResearchPlan,
      obj.researchFunds
    );
  }

  static set registrationParam(value: RegistrationParam) {
    if (!value) {
      sessionStorage.removeItem(this._registrationParamKey);
      return;
    }
    const text = JSON.stringify ({
      userId: value.userId,
      userName: value.userName,
      userNameSei: value.userNameSei,
      userNameMei: value.userNameMei,
      userNameSeiKn: value.userNameSeiKn,
      userNameMeiKn: value.userNameMeiKn,
      userNameSeiEn: value.userNameSeiEn,
      userNameMeiEn: value.userNameMeiEn,
      userEmail: value.userEmail,
      institutionName: value.institutionName,
      institutionPostCode: value.institutionPostCode,
      institutionAddress: value.institutionAddress,
      jspMemberFlag: value.jspMemberFlag,
      jspMembershipId: value.jspMembershipId,
      jspCouncilorName: value.jspCouncilorName,
      jspCouncilorMembershipId: value.jspCouncilorMembershipId,
      subjectName: value.subjectName,
      downloadPrivilege: value.downloadPrivilege,
      plannedNumberOfImages: value.plannedNumberOfImages,
      academicResearchObjective: value.academicResearchObjective,
      academicResearchPlan: value.academicResearchPlan,
      researchFunds: value.researchFunds
    });
    sessionStorage.setItem(this._registrationParamKey, text);
  }
}