<template>
  <div class="app-root" @contextmenu.self.prevent>
    <div class="app-section">
    <router-view class="view" />
    </div>
  </div>
</template>

<script>
import Vue from "vue";

export default class App extends Vue {
}
</script>

<style>
.app-root {
  display: flex;
  flex-direction: column;
  background-color:rgb(225, 235, 248);
}
.app-section {
  flex: 1;
  overflow: hidden;
}
</style>
